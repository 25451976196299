/**
 * Created by bootdo.
 * 用户相关api
 */
import * as API from '../../utils/request';

export default {
    export: params => {  return API.GET('data/export', params) },
    impdata: params => {  return API.UPLOAD('data/impdata', params) },
}
