<template>
  <div class="full_page">
    <el-form :model="formdata" label-width="100px" v-loading="loading">

      <el-row>
        <el-col :span="12">
          <el-form-item label="页眉" prop="hedemark">
            <el-radio-group v-model="formdata.hedemark">
              <el-radio label="" checked>无</el-radio>
              <el-radio label="中孚验证">文本</el-radio>
              <el-radio label="static/images/1.png">水印</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="水印" prop="watermark">
            <el-radio-group v-model="formdata.watermark">
              <el-radio label="" checked>无</el-radio>
              <el-radio label="中孚验证">文本</el-radio>
              <el-radio label="static/images/1.png">水印</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="格式" prop="type">
            <el-radio-group v-model="formdata.filetype">
              <el-radio :label="0" checked>PDF</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="12" v-if="auth['rp:m:time']">
          <el-form-item label="下载报告时间">
          <el-date-picker type="datetime"  v-model="formdata.time"  value-format="YYYY-MM-DD HH:mm:ss"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="排除测点">
        <el-checkbox-group v-model="formdata.notpoitName" >
          <el-checkbox label="'门口'" >门口</el-checkbox>
          <el-checkbox label="'回风口'">回风口</el-checkbox>
          <el-checkbox label="'死角'" >死角</el-checkbox>
          <el-checkbox label="'风机口'">风机口</el-checkbox>
          <el-checkbox label="'灯'" >灯</el-checkbox>
          <el-checkbox label="'环境'" >环境</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="排除测点">
        <el-input v-model="formdata.notpoitName"  ></el-input>
      </el-form-item>

      <el-checkbox-group v-model="formdata.checkList">
        <el-form-item label="数据" prop="type">
          <el-row>
            <el-col :span="12">
              <el-checkbox label="0">温度分布特性数据</el-checkbox>
              <span class="blue" @click="view(0)">&nbsp;&nbsp;&nbsp;&nbsp;查看<i class="el-icon-view"></i></span>
              <span class="blue" @click="dowfile(0,0)">&nbsp;&nbsp;&nbsp;&nbsp;下载<i
                  class="el-icon-download"></i></span>
            </el-col>
            <el-col :span="12">
              <el-checkbox label="4">湿度分布特性数据</el-checkbox>
              <span class="blue" @click="view(4)">&nbsp;&nbsp;&nbsp;&nbsp;查看<i class="el-icon-view"></i></span>
              <span class="blue" @click="dowfile(4,0)">&nbsp;&nbsp;&nbsp;&nbsp;下载<i
                  class="el-icon-download"></i></span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="&nbsp;&nbsp;" prop="type">
          <el-row>
            <el-col :span="12">
              <el-checkbox label="1">稳定运行开门断电曲线</el-checkbox>
              <span class="blue" @click="view(1)">&nbsp;&nbsp;&nbsp;&nbsp;查看<i class="el-icon-view"></i></span>
              <span class="blue" @click="dowfile(1,0)">&nbsp;&nbsp;&nbsp;&nbsp;下载<i
                  class="el-icon-download"></i></span>
            </el-col>

<!--            <el-col :span="12">-->
<!--              <el-checkbox label="2" disabled>验证历史数据</el-checkbox>-->
<!--              <span class="blue" @click="view(2)">&nbsp;&nbsp;&nbsp;&nbsp;查看<i class="el-icon-view"></i></span>-->
<!--              <span class="blue" @click="dowfile(2,0)">&nbsp;&nbsp;&nbsp;&nbsp;下载<i-->
<!--                  class="el-icon-download"></i></span>-->
<!--            </el-col>-->
            <el-col :span="12">
              <el-checkbox label="3">验证日志</el-checkbox>
              <span class="blue" @click="view(3)">&nbsp;&nbsp;&nbsp;&nbsp;查看<i class="el-icon-view"></i></span>
              <span class="blue" @click="dowfile(3,0)">&nbsp;&nbsp;&nbsp;&nbsp;下载<i
                  class="el-icon-download"></i></span>
            </el-col>
          </el-row>
        </el-form-item>
      </el-checkbox-group>
    </el-form>
    <div class="dialog-footer center">
      <el-button type="primary" @click="dowfile(-1)" style="width: 60%" :disabled="formdata.checkList.length==0">下载
      </el-button>
    </div>
  </div>
</template>

<script>
import {getUrl, DOWNLOADFILE} from "../../../utils/request";
import syscomm from "../../../utils/syscomm";

export default {
  props: {
    mid: {
      type: Number,
      default: 0
    },
    pn: {
      type: String,
      default: ''
    },
    reportshow: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      auth: {},
      loading: false,
      formdata: {hedemark: '', watermark: '', filetype: 0, checkList: [],time:undefined,notpoitName:[]}
    }
  },
  mounted() {
    this.init();
  },
  onBeforeUnmount() {
    this.closedig();
  },
  methods: {
    init() {
      this.auth = this.$getAuth();
      this.init_data();
    },
    init_data() {


    },
    view(type) {
      let notpoitName=this.formdata.notpoitName;
      let url = getUrl() + "/yanzheng-web/report/view?" + ["mid=", this.mid, '&type=', type,      '&token=' + localStorage.getItem("access-token") , '&notpoitName=', notpoitName ].join("");
      window.open(url, '_blank')
    },

    setnopoint(){
      this.pmInfo.notpoitName= this.pmInfo.notpoitName1;
    },

    dowfile(type) {
      this.loading = true;
      let filename = type > -1 ? this.pn + '_' + ['温度数据', '开门断电', '验证数据', '验证日志','湿度数据'][type] : this.pn;
      let params = {...this.formdata, mid: this.mid, type: type, fileName: filename}
      let doctype = type == -1 ? '.zip' : (this.formdata.filetype == 0 ? '.pdf' : '.doc')
      DOWNLOADFILE("report/file", params).then(res => {
        this.loading = false;
        syscomm.dowFile(res.data, filename + doctype);
      }).catch(res => {
        this.loading = false;
      })
    },
    //-------------------------------------------------------------------------------------------------------------------


  },
}
</script>
<style scoped>
.box-card {
  width: 390px;
  margin: 10px;
  display: inline-block;
}

.full_page >>> .el-input__inner {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;

}

</style>


