var Highcharts = require('highcharts');
Highcharts.setOptions({
    lang: {loading: '数据加载中...'},
    global: {useUTC: false}, // 是否使用世界标准时间
    credits: {enabled: false}, // 不显示LOGO
    colors: ['#00BFFF', '#50B432', '#fd631e', 'rgb(73,130,185)', '#f56c6c']
})
export default {
    setChart(id,data) {
        let options = {
            title: {
                text: data.title
            },
            xAxis: {
                type: 'datetime',
                crosshair: true,
                labels: {
                    formatter: function () {
                        return Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.value)
                    }
                },
            },
            yAxis: {
                title: {
                    text: data.ytitle
                },
                plotLines: []
            },
            tooltip: {
                formatter: function (e) {
                    return '<strong>' + this.series.name + '</strong>' +
                        Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', this.x) + ': ' + this.y + data.unit;
                }
            },

            plotOptions: {
                series: {
                    turboThreshold: 0,
                    showSymbol: false,
                    label: {
                        connectorAllowed: false
                    },
                }
            },
            series: data.series,
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 500
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }
        };

        if (data.min&&data.max) {
            options.yAxis.max = data.max;
            options.yAxis.min = data.min;
            options.yAxis.plotLines=[{
                color: '#008080',
                width: 3,
                value: data.min,
                dashStyle: 'dash',
                zIndex: 1,
                label: {
                    text: '下限值(' + data.min + data.unit + ')',
                    style: {
                        color: '#008080',
                    }
                }
            },

                {
                    color: '#ff0000',
                    width: 3,
                    value: data.max,
                    dashStyle: 'dash',
                    zIndex: 1,
                    label: {
                        text: '上限值(' + data.max + data.unit + ')',
                        style: {
                            color: '#ff0000',
                        }
                    }
                }
                ]
        }
        // 图表初始化函数
        return Highcharts.chart(id, options);
    },


}
