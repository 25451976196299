<template>
  <div class="full_page">
    <el-table :data="envlist" v-loading="loading" element-loading-text="拼命加载中" border
              style="width: 100%; height:100%">
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column prop="type1" label="验证类型1" width="150"></el-table-column>
      <el-table-column prop="type2" label="验证类型2" width="150"></el-table-column>
      <el-table-column prop="type3" label="验证类型3" width="150"></el-table-column>
      <el-table-column prop="starttime" label="开始时间"></el-table-column>
      <el-table-column prop="endtime" label="结束时间"></el-table-column>
      <el-table-column label="操作" conter width="210">
        <template #default="scope">
          <el-button type="text" icon="el-icon-edit" v-if="auth['pm:update']"
                     @click="handle_edit(scope.$index, scope.row)">编辑
          </el-button>
          <el-button type="text" icon="el-icon-delete" class="red" v-if="auth['pm:delete']"
                     @click="handle_del(scope.$index, scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="验证时间段" width="1400px" v-model="digShow" :close-on-click-modal="false">
      <el-form :model="cuttdata" ref="dataform" :rules="datarules" class="demo-ruleForm" label-width="160px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="验证类型" prop="type1">
              <el-select v-model="cuttdata.type1" placeholder="请选择" allow-create filterable
                         default-first-option>
                <el-option
                    v-for="item in runstatus"
                    :key="item"
                    :label="item.name"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="风机" prop="type2">
              <el-select v-model="cuttdata.type2" placeholder="请选择" allow-create filterable
                         default-first-option>
                <el-option
                    v-for="item in blow"
                    :key="item"
                    :label="item.name"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="验证阶段" prop="type3">
              <el-select v-model="cuttdata.type3" placeholder="请选择" filterable allow-create
                         default-first-option>
                <el-option
                    v-for="item in test"
                    :key="item"
                    :label="item.name"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="开始时间" prop="starttime">
              <el-date-picker
                  v-model="cuttdata.starttime"
                  type="datetime"
                  placeholder="选择日期时间" value-format="YYYY-MM-DD HH:mm:00">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="结束时间" prop="endtime">
              <el-date-picker
                  v-model="cuttdata.endtime"
                  type="datetime"
                  placeholder="选择日期时间" value-format="YYYY-MM-DD HH:mm:00">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="digShow=false">取 消</el-button>
        <el-button type="primary" @click="saveet('dataform')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {GET, PUT, DELETE} from "../../../utils/request";
import PM from "@/api/base/api_pm";
import DATA from "@/api/base/api_data";

export default {
  props: {
    mid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      auth: {},
      loading: false,
      lock: false,
      envlist: [],
      runstatus: [{
        name: '空载',
        value: '0'
      }, {
        name: '满载',
        value: '1'
      }],
      blow: [{
        name: '主风机',
        value: '0'
      }, {
        name: '备风机',
        value: '1'
      }, {
        name: '风机联动',
        value: '2'
      }],
      test: [{
        name: '预冷',
        value: '0'
      }, {
        name: '预验证',
        value: '1'
      }, {
        name: '开门测试',
        value: '2'
      }, {
        name: '断电测试',
        value: '3'
      }, {
        name: '均匀测试',
        value: '4'
      }],
      digShow: false,
      cuttdata: {type1: undefined, type2: undefined, type3: undefined, starttime: undefined, endtime: undefined},
      datarules: {
        type1: [{required: true, message: '请选择', trigger: 'blur'}],
        type2: [{required: true, message: '请选择', trigger: 'blur'}],
        type3: [{required: true, message: '请选择', trigger: 'blur'}],
        starttime: [{required: true, message: '请设置开始验证时间', trigger: 'blur'}],
        endtime: [{required: true, message: '请设置结束验证时间', trigger: 'blur'}],
      },
    }
  },
  mounted() {
    this.init();
  },
  onBeforeUnmount() {
    this.closedig();
  },
  methods: {
    init() {
      this.auth = this.$getAuth();
      this.init_data();
    },
    init_data() {
      GET('et/list', {mid: this.mid}).then((res) => {
        this.envlist = res.data;
      })
    },

    handle_edit(index, data) {
      this.cuttdata = data;
      this.digShow = true;
    },
    handle_del(index, data) {
      this.$confirm('此操作将删除[' + data.type3 + '] 验证阶段, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DELETE("et/delete", {id: data.id}).then((res) => {
          if (200 == res.code) {
            this.$message.success("操作成功！");
            this.initPageData();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(() => {
      });

    },
    saveet(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.lock) return;
          this.lock = true;
          PUT("et/info", this.cuttdata).then((res) => {
            this.lock = false;
            if (200 === res.code) {
              this.digShow=false;
              this.$message.success("操作成功！");
              this.init_data()
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });


    },
    //-------------------------------------------------------------------------------------------------------------------


  },
}
</script>
<style scoped>
.box-card {
  width: 390px;
  margin: 10px;
  display: inline-block;
}

.full_page >>> .el-input__inner {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;

}

</style>


