<template>
  <div class="full_page">

    <div>
      <el-steps :active="active" align-center>
        <el-step title="网关配置"></el-step>
        <el-step title="测点配置"></el-step>
      </el-steps>
    </div>

    <div v-show="1==active">
      <el-row>
        <el-col :span="12">
          <el-button type="primary" @click="add_ap" style="width: 96%;">添加网关</el-button>
        </el-col>
        <el-col :span="12">
          <el-button type="success" style="width: 96%" @click="go(2)">下一步</el-button>
        </el-col>
      </el-row>
      <el-card class="box-card" v-for="(apinfo,i) in aplist" :key="i">
        <i class="el-icon-upload" style="font-size: 100px;float: left;" :style="{color: 1==apinfo.status?'green':'red'}"
           @click="edit_apinfo(apinfo)"></i>
        <span style="float: right">
          <span style="font-size: 25px;right: 0px">{{ apinfo.apid }}</span>
           <span>
             <i class="el-icon-delete red fr" @click="delap(apinfo)"></i>
          </span>
        </span>
      </el-card>
    </div>

    <div v-show="2==active">
      <el-row style="margin: 5px;">
        <el-col :span="8">
          <el-select v-model="sel_snpotint" placeholder="请选择测点" style="width: 60%" @change="pickerCountry">
            <el-option
                v-for="(item,index) in snconfig"
                :key="index"
                :value-key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
          <el-button type="info" icon="el-icon-setting" style="margin-left: 5px;width: 35%" @click="add_sn()"> 测点设置
          </el-button>

        </el-col>
        <el-col :span="8"><span style=""> 总数：{{ allCardSzie }}</span></el-col>
        <el-col :span="8"><span>剩余：{{ allCardSzie - bdcards.length }}</span></el-col>
      </el-row>
      <el-row style="margin: 5px;">
        <el-col :span="8">
          <el-button type="primary" style="width: 96%" @click="go(1)">上一步</el-button>
        </el-col>
        <el-col :span="8" style="text-align: center">
          <el-button icon="el-icon-link" circle @click="connect()" :type="connected?'success':'warning'"></el-button>
        </el-col>
        <el-col :span="8">
          <el-button type="success" style="width: 96%" @click="finish()">开始采集</el-button>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="6" v-for="(item,index) in cardMappinglist" :key="index" style="padding: 5px;">
          <span style="width: 80px;display: inline-flex;">序号： {{ item.pointnum }}</span>
          <el-input
              placeholder="卡片ID"
              v-model="item.snid"
              clearable :color="1==item.status?'#00FF7F':'red'" size="small" style="width: 60%">
          </el-input>
          <i class="el-icon-delete red" @click="handleClick(item)"></i>
        </el-col>
      </el-row>
      <div>
        <el-button type="warning" style="width: 100%" @click="save_card">确认</el-button>
      </div>
    </div>
    <div v-show="3==active" style="text-align: center">
       <i class="el-icon-success green" style="font-size: 5vw;"></i>
        <el-alert
        title="成功提示的文案"
        type="success">
    </el-alert>
    </div>

    <!--    以下为对话框部分-->
    <el-dialog title="AP配置" v-model="ap_dig">
      <el-form :model="apform" :rules="aprules" ref="apform" class="demo-ruleForm">
        <el-form-item label="网关编号" prop="apid" label-width="100px">
          <el-input v-model="apform.apid" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ap_dig = false">取 消</el-button>
        <el-button type="primary" @click="save_apinfo">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="卡片设置" v-model="sn_dig">

      <el-row v-for="(item,index) in datalist" :key="index">
        <el-col :span="12">
          <el-input
              placeholder="测点"
              v-model="item.name"
              clearable style="border: none">
          </el-input>

        </el-col>
        <el-col :span="12">
          <el-input
              placeholder="范围,用 - 链接 "
              v-model="item.value"
              clearable style="width: 90%">
          </el-input>
          <i class="el-icon-delete  red fr" style="margin-top: 15px" @click="sn_delete(item,index)"></i>
        </el-col>
      </el-row>

      <div slot="footer" class="dialog-footer" style="margin-top: 10px;">
        <el-button @click="sn_dig = false;">取 消</el-button>
        <el-button type="success" @click="sn_add">添加</el-button>
        <el-button type="primary" @click="save_sninfo">确 定</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import {getUrl, GET, POST, DELETE, POSTARRAY} from "../../../utils/request";

export default {
  name: "mitdata",
  props: {
    mid: {
      type: Number,
      default: 0
    },
    apconfshow:{
       type:Boolean,
       default:true
    }
  },
  data() {
    return {
      auth: {},
      // mid: 1464,
      active: 1,
      //ap 配置
      apform: {apid: ''},
      aplist: [],
      ap_dig: false,
      aprules: {
        apid: [
          {required: true, message: '请输入AP 编号', trigger: 'blur'},
        ],
      },
      //sn配置
      sel_snpotint: [],//选择的测点
      snconfig: [],
      cardMappinglist: [],


      allCardSzie: 0,
      bdcards: [],
      timer: undefined,
      connected: false,
      socketTask: false,
      //
      sn_dig: false,
      datalist: [],
    }
  },
  mounted() {
    this.init();
  },
  onBeforeUnmount() {
    this.closedig();
  },
  methods: {
    init() {
      this.auth = this.$getAuth();
      this.init_data();
    },
    go(index) {
      this.active = index;
      this.init_data();
    },
    init_data() {
      if (1 == this.active) {
        this.init_aplist();
      } else if (2 == this.active) {
        this.init_sn_config();
      } else if (3 == this.active) {
        this.finish();
      }
    },


    //ap ==========================================================================================
    init_aplist() {
      GET("ap/list", {
        mid: this.mid
      }).then((res) => {
        if (res.code == 200) {
          this.aplist = res.data;
        }
      });
    },
    add_ap() {
      this.apform = {apid: ''};
      this.ap_dig = true;
    },
    edit_apinfo(apinfo) {
      this.apform = {...apinfo}
      this.ap_dig = true;
    },
    save_apinfo() {
      this.$refs['apform'].validate((valid) => {
        if (valid) {
          this.apform.mid = this.mid;
          POST("ap/apinfo", this.apform).then((res) => {
            this.ap_dig = false;
            if (res.code == 200) {
              this.init_aplist();
            } else {
              this.$message.error(res.msg)
            }
          });
        }
        return false;
      });
    },
    delap(apinfo) {
      this.$confirm('此操作将删除[' + apinfo.apid + '] 网关, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        DELETE("ap/delete", apinfo).then((res) => {
          if (res.code == 200) {
            this.init_aplist();
          } else {
            this.$message.error(res.msg || '加载失败');
          }
        });
      })
    },
    //sn 开始==========================================================================================
    init_sn_config() {
      GET("sn/get", {
        mid: this.mid
      }).then((res) => {
        if (res.code == 200) {
          if (res.data && res.data.mapping && res.data.mapping.length > 0) {
            this.snconfig = JSON.parse(res.data.mapping);
            let allszie = 0;
            for (let item of this.snconfig) {
              let ses = item.value.split(",");
              for (let se of ses) {
                let ss = se.split("-");
                if (ss.length == 1) {
                  allszie++;
                } else {
                  allszie += ss[1] - ss[0] + 1
                }
              }
            }
            this.allCardSzie = allszie;
            this.sel_snpotint = this.snconfig[0];
            this.pickerCountry();
          } else {
            this.snconfig = [];
          }
        } else {
          this.$message.error(res.msg || '加载失败');
        }
      });
    },
    pickerCountry(e, index) {
      this.init_sn_mapping();
    },
    init_sn_mapping() {
      if (undefined == this.sel_snpotint || undefined == this.sel_snpotint.name) return;
      GET("sn/cards", {
        mid: this.mid,
        name: this.sel_snpotint.name
      }).then((res) => {
        if (res.code == 200) {
          this.bdcards = res.allcards;
          let sn_list = res.data, sn_obj = {}, cs = 0, bs = 0;
          if (sn_list.length > 0) {
            for (let sn of sn_list) {
              sn_obj[sn.pointnum] = sn;
            }
          }
          sn_list = [];
          if (this.sel_snpotint) {
            let ses = this.sel_snpotint.value.split(",");
            for (let se of ses) {
              let ss = se.split("-");
              if (ss.length == 1) ss.push(ss[0]);
              let s = parseInt(ss[0]), e = parseInt(ss[1]);
              for (; s <= e; s++) {
                cs++;
                if (sn_obj[s]) {
                  sn_list.push(sn_obj[s]);
                  delete sn_obj[s];
                } else {
                  sn_list.push({
                    mid: this.mid,
                    pointname: this.sel_snpotint.name,
                    pointnum: s,
                    snid: undefined,
                    status: 0
                  });
                }
              }
            }
            //错误的卡片
            if (Object.keys(sn_obj)) {
              for (let k in sn_obj) {
                let e_sn = sn_obj[k];
                e_sn.err = true;
                sn_list.push(e_sn);
              }
            }
          }
          this.cardMappinglist = sn_list;
        } else {
          this.$message.error(res.msg || '加载失败');
        }
      });

    },
    save_card() {
      if (!this.cardMappinglist || this.cardMappinglist.length == 0) {
        this.$message.error('当前配置为空！');
        return;
      }
      let extdata = [];
      for (let card of this.cardMappinglist) {
        if (!card.snid || '' == card.snid || card.err) {
          this.$message.error('信息不完整！');
          return;
        }
        if (card.err) {
          this.$message.error('存在异常卡片！');
          return;
        }
        if (extdata.indexOf(card.snid) > -1) {
          this.$message.error('卡片' + card.snid + '重复！');
          return;
        }
        extdata.push(card.snid);
      }
      POSTARRAY("sn/binding", {
        mid: this.mid,
        data: JSON.stringify(this.cardMappinglist)
      }).then((res) => {
        if (res.code == 200) {
          this.bdcards = res.data;
          this.$message.success("操作成功！");
          this.init_sn_mapping();
        } else {
          this.$message.error(res.msg || '加载失败');
        }
      });
    },
    connect() {
      try {
        if (this.connected && this.socketTask) {
          this.socketTask.close();
          this.socketTask = undefined;
          clearInterval(this.timer);
        } else {
          this.socketTask = new WebSocket(getUrl().replace('http', 'ws') + '/yanzheng-prodev/na_dw/1/' + this.mid)
          this.socketTask.onopen = (res) => {
            this.connected = true;
            this.timer = setInterval(() => {
              this.socketTask.send({
                data: '{"mid":' + this.mid + '}'
              })
            }, 30000);
          };
          this.socketTask.onerror = (err) => {
            this.connected = false
          };
          this.socketTask.onmessage = (res) => {
            //this.bindingCard(res.data);
          };
          this.socketTask.onclose = (res) => {
            this.connected = false
            this.socketTask = undefined
          };
        }
      } catch (e) {
        console.log(e);
      }
    },

    bindingCard(res) {
      let data = JSON.parse(res);
      if (data && 1 == data.type) {
        //判断已绑定的卡片
        if (this.bdcards.indexOf(data.sid) > -1) {
          this.$message.error("当前卡片已经绑定！");
          return;
        }
        //判断当前缓存的卡片
        for (let card of this.cardMappinglist) {
          if (card.snid == data.sid) {
            this.$message.error("当前卡片已经绑定！");
            return;
          }
        }
        //设置值
        for (let card of this.cardMappinglist) {
          if (!card.snid || '' == card.snid) {
            card.snid = data.sid;
            return;
          }
        }
      }
    },
    handleClick(item) {
      if (item.id) {
        POST("sn/binding", {
          mid: this.mid,
          ids: item.id
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success("操作成功！");
            this.init_sn_mapping();
          } else {
            this.$message.error(res.msg || '加载失败');
          }
        });
      } else {
        item.snid = '';
      }
    },
    //sn配置======================================================================================================
    add_sn() {
      this.init_list();
      this.sn_dig = true;
    },

    init_list() {
      GET("sn/get", {
        mid: this.mid
      }).then((res) => {
        if (res.code == 200) {
          let data = res.data;
          if (data && data.mapping.length > 0) {
            this.id = data.id;
            this.datalist = JSON.parse(data.mapping);
          }
        } else {
          this.$message.error(res.msg || '加载失败');
        }
      });
    },

    sn_add() {
      this.datalist.push({
        name: undefined,
        value: undefined
      })
    },
    sn_delete(data, index) {
      if (this.id && data.name) {
        GET("sn/check",  {
          mid: this.mid,
          name: data.name
        }).then((res) => {
          if (res.data > 0) {
            this.$message.error("当前测点存在绑定的卡片！请先解绑卡片！");
          } else {
            this.datalist.splice(index, 1);
          }
        });
      } else {
        this.datalist.splice(index, 1);
      }
    },
    save_sninfo() {
      let re = /^[0-9]*-[0-9]*/;
      for (let item of this.datalist) {
        if (item.name == undefined || item.name == '' || item.value == undefined || item.value == '' || !re.test(item.value)) {
          this.$message.error("请输入完整正确的信息！");
          return;
        }
      }
      POST("sn/info", {
        id: this.id,
        mid: this.mid,
        mapping: JSON.stringify(this.datalist)
      }).then( (res) => {
            this.sn_dig = false;
            if (res.code == 200) {
              this.init_sn_config();
            } else {
              this.$message.error(res.msg);
            }
          });
    },

    //-------------------------------------------------------------------------------------------------------------------

    //开始采集
    finish() {
      this.$confirm('确定要开始采集吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        POSTARRAY("pm/status", {
          id: this.mid,
          status: 1
        }).then((res) => {
          if (res.code == 200) {
            this.$message.success('系统开始采集！');
            this.$emit("chage_apconfshow",false)
            debugger
          } else {
            this.$message.error(res.msg || '加载失败');
          }
        });
      }).catch(() => {
        this.$emit("chage_apconfshow",false);
      })
    }

  },
}
</script>
<style scoped>
.box-card {
  width: 390px;
  margin: 10px;
  display: inline-block;
}

.full_page >>> .el-input__inner {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0px;
  padding: 5px;
}

</style>


