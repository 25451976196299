<style scoped>
.chart_dev{
  margin-top: 10px;
  width: 100%;
  height: 480px;
}
</style>
<template>
  <div>
    <el-button-group style="margin-bottom: 10px;">
      <el-button type="primary" size="mini" icon="el-icon-view" @click="change_mit(0)">实时数据</el-button>
      <el-button type="primary" size="mini" icon="el-icon-pie-chart" @click="change_mit(1,0)">温度数据</el-button>
      <el-button type="primary" size="mini" icon="el-icon-ice-cream-square" @click="change_mit(1,1)">湿度数据</el-button>
    </el-button-group>
    <div v-show="formdata.type==0">
      <el-table size="mini" :data="mitDatas" height="560" border style="width: 100%">
        <el-table-column type="index" width="50" s></el-table-column>
        <el-table-column prop="pointname" label="测点" width="180" sortable></el-table-column>
        <el-table-column prop="sid" label="卡片" width="180"></el-table-column>
        <el-table-column prop="temp" label="温度(℃)"></el-table-column>
        <el-table-column prop="rh" label="湿度(%)"></el-table-column>
        <el-table-column prop="du" label="电压(v)"></el-table-column>
        <el-table-column prop="rssi" label="信号(DB)"></el-table-column>
      </el-table>
    </div>
    <div v-show="formdata.type==1">
      <el-row>
        <el-col :span="12">
          <div style="margin: 5px;">点位设置：
            <el-checkbox-group v-model="filter.names" style="display: contents;">
              <el-checkbox v-for="(item, index) in cnlist" :label="item.name" :key="index">{{item.name}}</el-checkbox>
            </el-checkbox-group>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="margin: 5px;">验证阶段：
            <el-button size="mini" v-for="(item, index) in statuList" :key="index"
                       shape="circle" :type="filter.st==item.id?'green':'btn-gray'" @click="setTime(item)">
              {{ item.type1 + '-' + item.type2 + '-' + item.type3 }}
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <div style="margin: 5px;">
            验证范围：
            <el-input size="mini" placeholder="请输入验证范围,用'_'隔开,如2_8" v-model="filter.vifrange" style="width: 75%;"/>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="margin: 5px;">
            时间范围：
            <el-date-picker size="mini" v-model="setime" type="datetimerange" style="width: 65%;"
                            range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" align="center"></el-date-picker>
            <el-button size="mini" type="success" style="margin-left: 10px;" @click="gethistorydata">查询</el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <div ref="chart_div" class="chart_dev"></div>
      </el-row>
    </div>
  </div>
</template>

<script>
import * as API from '../../../utils/request';
import syscomm from '../../../utils/syscomm';
import charts from '../../../utils/charts';
import Highcharts from 'highcharts/highstock';

export default {
  name: "mitdata",
  props: {
    mid: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      auth: {},
      // mid: 1363,
      //实时数据&&曲线
      formdata: {type: 0,mid:undefined},
      mitDatas: [],
      reftime: undefined,
      //历史数据
      statuList: undefined,
      cnlist: [],
      filter: {
        st: undefined,
        stt: '',
        tm: '',
        type: 0,
        names: [],
        vifrange: '',
      },
      setime: [syscomm.dateFtt("yyyy-MM-dd hh:mm:ss", syscomm.getStartTime(0.125)), syscomm.dateFtt("yyyy-MM-dd hh:mm:ss", new Date())],

    }
  },
  mounted() {
    this.init();
  },
  onBeforeUnmount() {
    this.closedig();
  },
  methods: {
    init() {
      this.auth = this.$getAuth();
      this.init_base();
    },
    init_base() {
      this.formdata. mid=this.mid;
      this.change_mit(this.formdata.type);
    },
    closedig() {
      if (this.reftime) {
        clearInterval(this.reftime);
        this.reftime = undefined;
      }
    },
    change_mit(type, datatype) {
      this.formdata.type = type || 0;
      if (this.reftime) {
        clearInterval(this.reftime);
        this.reftime = undefined;
      }
      if (0 == type) {
        this.getmitdata();
        this.reftime = setInterval(this.getmitdata, 30000)
      } else {
        this.filter.type = datatype;
        if (undefined == this.statuList) {
          Promise.all([
            this.init_stList(),
            this.init_carlist(),
            this.init_pminfo(),
          ]).then(res => {
            this.gethistorydata();
          })
        } else {
          this.gethistorydata();
        }
      }
    },
    getmitdata() {
      API.GET('data/mit', this.formdata).then((res) => {
        if (res.code == 200) {
          this.mitDatas = res.data;
        }
      });
    },
    //-====================================================================================================
    init_stList() {
      API.GET("et/list", {
        mid: this.mid
      }).then((res) => {
        this.statuList = res.data;
      });
    },
    init_carlist() {
      API.GET("sn/get", {mid: this.mid}).then((res) => {
        if (res.code == 200) {
          let data = res.data;
          if (data && data.mapping.length > 0) {
            this.cnlist = JSON.parse(data.mapping);
          }
        } else {
          this.$message.error(res.msg || '加载失败');
        }
      });
    },
    init_pminfo() {
      API.GET("pm/get", {id: this.mid,}).then((res) => {
        if (200 == res.code && res.data) {
          let pm = res.data;
          this.filter.vifrange = pm.vifrange;
        }
      });
    },
    //
    setTime(item) {
      this.filter.st = item.id;
      this.filter.endTime = item.endtime;
      this.filter.startTime = item.starttime;
    },
    pickerCountry(e, index) {
      let item = this.statuList[e ? e.detail.value : index];
      this.filter.stt = item.title;
      this.filter.endtime = item.endtime;
      this.filter.startTime = item.startTime;
    },
    setSn(item) {
      let i = this.filter.names.indexOf(item.name);
      if (i == -1) {
        this.filter.names.push(item.name);
      } else {
        this.filter.names.splice(i, 1);
      }
      debugger
    },
    gethistorydata() {
      this.filter.mid = this.mid;
      if (this.filter.vifrange && this.filter.vifrange.length > 0) {
        let vifrange = this.filter.vifrange.split("_");
        if (vifrange.length == 2 && !isNaN(vifrange[0]) && !isNaN(vifrange[1])) {

        } else {
          this.$message.error('验证范围设置错误！请使用"_"分割！');
          return;
        }
      }
      if (this.setime && this.setime.length == 2) {
        this.filter.startTime = this.setime[0];
        this.filter.endTime = this.setime[1];
      }else{
         this.filter.startTime=undefined
         this.filter.endTime=undefined;
      }
      API.POSTARRAY("data/data", this.filter).then((res) => {
        if (res.code == 200) {
          this.init_chardata(res.cards, res.data);
        } else {
          this.$message.error(res.msg || '加载失败');
        }
      });
    },
    init_chardata(cards, alldata) {
      let tite=this.filter.type==0?'温度':'湿度';
      let unit=['℃' , '%'][ this.filter.type ||0];
      let data = {title: tite+'数据', ytitle:tite, series: [],unit:unit}
      if (this.filter.vifrange.length > 0) {
        let vifrange = this.filter.vifrange.split("_");
        if (vifrange.length == 2&&this.filter.type==0) {
          data.min = vifrange[0];
          data.max = vifrange[1];
        }
      }
      let datas;
      let newdatas;
      for (let card of cards) {
        newdatas = [];
        datas = alldata[card.snid];
        for (const point of datas) {
          point[0]=new Date(point[0]).getTime();
        }
        data.series.push({
          name: card.pointname+"-"+card.pointnum,
          data: datas,
          marker: {enabled: false},
          lineWidth: 2
        });
      }
      charts.setChart(this.$refs.chart_div,data)
    }

  },
}
</script>


