<style scoped>
.tree_tool {
  background-color: white;
  border-bottom: 1px solid #CCCCCC;
  font-size: 18px;
  padding: 5px 10px;
}

.breadcrumb {
  margin: 10px 0px;
  padding: 10px 0px;
  background-color: white;
}

.but {
  width: 49%;
  margin-bottom: 10px;
}

.full >>> .el-dialog__body {
  padding: 10px 20px;
  background-color: white;
}

.example-showcase .el-dropdown-link {
  margin-left: 5px;
}

</style>
<template>
  <div class="full">
    <el-row :gutter="5">
      <el-col :span="4">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
          <el-breadcrumb-item>Tree</el-breadcrumb-item>
          <el-breadcrumb-item v-show="cuttTreeNode">{{ cuttTreeNode && DTSTATUS[cuttTreeNode.data.type].name }}
          </el-breadcrumb-item>
          <el-breadcrumb-item v-show="cuttTreeNode">{{ cuttTreeNode && cuttTreeNode.data.name }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="grid-content bg-purple">
          <div class="handle-box">
            <el-tooltip class="item" effect="dark" content="刷新" placement="right">
              <el-button type="success" icon="el-icon-refresh" @click="refdept()"></el-button>
            </el-tooltip>
          </div>
          <el-tree lazy highlight-current :expand-on-click-node="false" ref="tree" node-key="{type}+'_'+{id}"
                   :load="loadTreeNode" @node-click="setCuttTreeNode" :props="defaultProps">
          </el-tree>
        </div>
      </el-col>
      <el-col :span="20">
        <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
          <el-breadcrumb-item>项目</el-breadcrumb-item>
          <el-breadcrumb-item>{{ page.total }}</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="grid-content bg-purple">
          <div class="tree_tool" style="overflow: hidden">
            <el-input
                placeholder="请输入内容"
                suffix-icon="el-icon-search"
                v-model="filter.key" @change="initPageData" style="width: 320px;margin-right: 10px;">
            </el-input>
            <el-button type="primary" icon="el-icon-plus" @click="addOBJ()" v-if="auth['pm:add']">添加</el-button>
            <el-button type="success" icon="el-icon-refresh" @click="initPageData()">刷新</el-button>
          </div>
          <el-table :data="page.list" v-loading="showLoading" element-loading-text="拼命加载中" border
                    style="width: 100%; height:50%">
            <el-table-column type="index" label="序号" width="60"></el-table-column>
            <el-table-column prop="name" label="项目名称" width="150"></el-table-column>
            <el-table-column prop="reportno" label="项目编号" width="150"></el-table-column>
            <el-table-column prop="address" label="地址"></el-table-column>
            <!--            <el-table-column prop="didName" label="所属部门" width="100"></el-table-column>-->
            <el-table-column prop="username" label="负责人" width="100"></el-table-column>
            <el-table-column prop="addtime" label="创建时间" width="180"></el-table-column>
            <!--            <el-table-column prop="activ" label="状态" width="50">-->
            <!--              <template  #default="scope"><i :class="PMSTATUS[scope.row.activ||1].icon"-->
            <!--                                              :style="{color:PMSTATUS[scope.row.activ||1].color}"></i></template>-->
            <!--            </el-table-column>-->
            <el-table-column label="操作" conter width="210">
              <template #default="scope">
                <el-button type="text" icon="el-icon-edit" v-if="auth['pm:update']"
                           @click="handle_edit(scope.$index, scope.row)">编辑
                </el-button>
                <el-button type="text" icon="el-icon-delete" class="red" v-if="auth['pm:delete']"
                           @click="handle_del(scope.$index, scope.row)">删除
                </el-button>

                <el-button type="text" icon="el-icon-share" v-if="auth['pm:share']"
                           @click="editorAuth(scope.row);userdigShow=true">分享
                </el-button>
                <el-button type="text" icon="el-icon-view" v-if="auth['pm:get']"
                           @click="showShare(scope.row);projectdigShow=true">解绑
                </el-button>
                <el-button type="text" icon="el-icon-connection" v-if="auth['ap:**']"
                           @click="apconf(scope.row); ">AP配置
                </el-button>
                <el-button type="text" icon="el-icon-s-data" v-if="auth['data:mit']&& scope.row.status==1"
                           @click="showmitdata(scope.row); ">实时数据
                </el-button>
                <el-button type="text" icon="el-icon-upload" v-if="auth['data:impdata']"
                           @click="showfiledata(scope.row); ">上传数据
                </el-button>
                <el-button type="text" icon="el-icon-time" v-if="auth['et:**']"
                           @click="showret(scope.row) ">验证阶段
                </el-button>
                <el-button type="text" icon="el-icon-download"
                           v-if="scope.row.status>1&& (auth['data:expcsv']||auth['data:exppdf'])"
                           @click="showdowfiledata(scope.row); ">下载数据
                </el-button>
                <el-button type="text" icon="el-icon-download" v-if="scope.row.status>1&& auth['rp:m:m']"
                           @click="showreport(scope.row); ">查看报告
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @current-change="devlogChange" :current-page="page.pageNum"
                         :page-size="page.pageSize" layout="total, prev, pager, next, jumper"
                         :total="page.total"></el-pagination>

        </div>
      </el-col>
    </el-row>

    <!--添加、编辑 项目对话框-->
    <el-dialog :title="pmInfo.name" width="1400px" v-model="pmdigShow" :close-on-click-modal="false">
      <el-form :model="pmInfo" ref="pmInfo" :rules="deptrules" class="demo-ruleForm" label-width="160px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input v-model="pmInfo.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="验证类型" prop="type">
              <el-select v-model="pmInfo.type" filterable placeholder="请选验证类型" style="width: 100%">
                <el-option v-for="item in verifyTypes" :key="item.value" :label="item.name"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="数据状态" prop="status">
              <el-select v-model="pmInfo.status" placeholder="请选择活动区域" style="width: 100%">
                <el-option label="未开始" :value="0"></el-option>
                <el-option label="待完善" :value="1"></el-option>
                <el-option label="采集中" :value="2"></el-option>
                <el-option label="已完成" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>


          <el-col :span="8">
            <el-form-item label="温度验证范围" prop="se">
              <el-col :span="11">
                <el-form-item label="" label-width="0" prop="ss">
                  <el-input v-model="pmInfo.ss" placeholder="下限"></el-input>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2"> _</el-col>
              <el-col :span="11" prop="se">
                <el-form-item label="" label-width="0" prop="se">
                  <el-input v-model="pmInfo.se" placeholder="上限"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="湿度验证范围" prop="er">
              <el-col :span="11">
                <el-form-item label="" label-width="0" prop="sr">
                  <el-input v-model="pmInfo.sr" placeholder="下限"></el-input>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2"> _</el-col>
              <el-col :span="11" p>
                <el-form-item label="" label-width="0" prop="er">
                  <el-input v-model="pmInfo.er" placeholder="上限"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="风机设定" prop="">
              <el-col :span="11">
                <el-form-item label="" label-width="0" prop="fs">
                  <el-input v-model="pmInfo.fs" placeholder="下限"></el-input>
                </el-form-item>
              </el-col>
              <el-col class="line" :span="2">_</el-col>
              <el-col :span="11" prop="se">
                <el-form-item label="" label-width="0" prop="fe">
                  <el-input v-model="pmInfo.fe" placeholder="上限"></el-input>
                </el-form-item>
              </el-col>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="地址" prop="address">
              <el-input v-model="pmInfo.address"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="负 责 人" prop="codename">
              <el-input disabled v-model="pmInfo.uidName"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>


          <el-col :span="8" v-if="auth['pm:sem']">
            <el-form-item label="开始时间" prop="startveriftime">
              <el-date-picker
                  v-model="pmInfo.startveriftime"
                  type="datetime"
                  placeholder="选择日期时间" value-format="YYYY-MM-DD HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="auth['pm:sem']">
            <el-form-item label="结束时间" prop="endveriftime">
              <el-date-picker
                  v-model="pmInfo.endveriftime"
                  type="datetime"
                  placeholder="选择日期时间" value-format="YYYY-MM-DD HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>


      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button @click="pmdigShow=false">取 消</el-button>
        <el-button type="primary" @click="saveobj('pmInfo')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 权限树状图-->
    <el-dialog title="分享" :close-on-click-modal="false" width="600px" v-model="userdigShow" @open="openDialogTree"
               @close='closeDialogUserTree'>
      <el-tree lazy show-checkbox highlight-current default-expand-all :expand-on-click-node="false" ref="userTree"
               node-key="identKey" :load="loadTreeNode" @node-click="setCuttTreeNode" :props="defaultProps">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <i :class="DTSTATUS[data.type].icon" :style="{color:DTSTATUS[data.type].color}"></i>
                   <span>{{ node.label }}</span>
                </span>
      </el-tree>
      <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialogUserTree();userdigShow = false">取 消</el-button>
                <el-button type="primary" @click="saveAuth()">确 定</el-button>
            </span>
    </el-dialog>
    <!-- 查看项目权限人员-->
    <el-dialog title="查看" :close-on-click-modal="false" width="600px" v-model="projectdigShow"
               @open="openShareUserTable" @close='closeDialogTree'>
      <el-table :data="shareUserList" border
                style="width: 100%; height:50%">
        <el-table-column type="id" label="序号" width="50"></el-table-column>
        <el-table-column prop="name" label="人员姓名"></el-table-column>
        <el-table-column prop="companyName" label="所属部门" width="200"></el-table-column>
      </el-table>
    </el-dialog>

    <!-- 上传数据-->
    <el-dialog title="上传数据" :close-on-click-modal="false" width="650px" v-model="updatefileShow"
               @close='updatefileShow=false' :destroy-on-close="true">
      <el-alert title="验证时间段用;分割" type="error"></el-alert>
      <el-form :model="formdata" :rules="fileDataRules" ref="fileDataForm">

        <el-form-item label="类型" prop="type">
          <el-radio-group v-model="formdata.type">
            <el-radio :label="0" checked>温度</el-radio>
            <el-radio :label="1">湿度</el-radio>
          </el-radio-group>
        </el-form-item>
        <!--        <el-form-item label="数据格式">-->
        <!--          <el-radio-group v-model="formdata.code">-->
        <!--            <el-radio label="自动" ></el-radio>-->
        <!--            <el-radio label="GBK"></el-radio>-->
        <!--            <el-radio label="UTF-8"></el-radio>-->
        <!--          </el-radio-group>-->
        <!--        </el-form-item>-->
        <el-form-item label="变更信息">
          <el-checkbox v-model="formdata.repcards" disabled>变更卡片</el-checkbox>
          <el-checkbox v-model="formdata.repettime" disabled>变更后缀</el-checkbox>
          <el-checkbox v-model="formdata.repveriftime" disabled>变更验证时间</el-checkbox>
        </el-form-item>
        <el-form-item label="数据" prop="datafile">
          <el-upload
              action="#"
              :limit="1"
              accept=".csv"
              :auto-upload="false"
              :on-change="setcsv"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <span slot="tip" class="el-upload__tip">只能上传csv文件</span>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updatefileShow = false">取 消</el-button>
        <el-button type="primary" :loading="ilock" :disabled="ilock" @click="updatedatafile()">确 定</el-button>
      </div>
    </el-dialog>


    <!-- 下载数据-->
    <el-dialog title="下载数据" :close-on-click-modal="false" width="580px" v-model="downdfileshow"
               @close='downdfileshow=false;'>
      <div v-if="!locck">
        <el-row v-if="auth['data:expsetttime']">
          <el-date-picker
              v-model="formdata.dowtime"
              type="datetime"
              placeholder="选择日期时间" style="width: 100%;margin-bottom: 5px">
          </el-date-picker>
        </el-row>
        <el-row v-if="auth['data:expcsv']">
          <el-button class="but" type="primary" @click="dowfile(0,0)">温度数据</el-button>
          <el-button class="but" type="primary" @click="dowfile(1,0)">湿度数据</el-button>
        </el-row>
        <el-row v-if="auth['data:exppdf']">
          <el-button class="but" type="success" @click="dowfile(0,2)">温度数据-PDF</el-button>
          <el-button class="but" type="success" @click="dowfile(1,2)">湿度数据-PDF</el-button>
        </el-row>
        <el-row v-if="auth['data:exppdf']">
          <el-button class="but" type="warning" @click="dowfile(0,1)">温度数据-PDF-ZIP</el-button>
          <el-button class="but" type="warning" @click="dowfile(1,1)">湿度数据-PDF-ZIP</el-button>
        </el-row>
        <el-row v-if="auth['data:expyz']">
          <el-button class="but" type="info" @click="dowfile(0,3)">温度数据-区块数据</el-button>
          <el-button class="but" type="info" @click="dowfile(1,3)">湿度数据-区块数据</el-button>
        </el-row>
        <el-row v-if="formdata.url">
          <a :href="formdata.url">{{ formdata.url }}</a>
        </el-row>
      </div>
      <el-row v-if="locck">
        <i class="el-icon-loading" style="font-size:5vw;margin-left: 35%; "></i>
      </el-row>
    </el-dialog>

    <el-dialog title="实时数据" :close-on-click-modal="false" width="1200px" height="550px" v-model="mitdigshow"
               @close='mitdigshow=false;' :destroy-on-close="true">
      <mitdata v-bind:mid="formdata.mid"></mitdata>
    </el-dialog>

    <el-dialog title="验证时段" :close-on-click-modal="false" width="1200px" height="550px" v-model="envdigshow"
               @close='mitdigshow=false;' :destroy-on-close="true">
      <envtime v-bind:mid="formdata.mid"></envtime>
    </el-dialog>

    <el-dialog title="AP配置" :close-on-click-modal="false" width="1200px" height="550px" v-model="apconfshow"
               @close='apconfshow=false;' :destroy-on-close="true">
      <apconf v-bind:mid="formdata.mid" v-bind:apconfshow="apconfshow"
              v-on:chage_apconfshow="close_apconfig($event)"></apconf>
    </el-dialog>

    <el-dialog title="报告" :close-on-click-modal="false" width="1200px" height="550px" v-model="reportshow"
               @close='reportshow=false;' :destroy-on-close="true">
      <report v-bind:mid="formdata.mid" v-bind:pn="formdata.name" v-bind:reportshow="reportshow"></report>
    </el-dialog>


  </div>
</template>

<script>
import DEPT from '../../../api/sys/api_dept';
import PM from '../../../api/base/api_pm';
import syscomm from '../../../utils/syscomm';
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import {LOAD_ROOT_OPTIONS} from '@riophae/vue-treeselect'
import COMM from "../../../api/base/api_comm";
import {getUrl} from "../../../utils/request";

import mitdata from "./mitdata"
import apconf from "./apconf";
import report from "./report";
import envtime from "./et.vue";
import DATA from "../../../api/base/api_data";

export default {
  name: "promange",
  components: {
    Treeselect,
    mitdata, apconf, report,envtime
  },
  data() {
    return {
      auth: {},
      options: null,
      //dept-->tree
      cuttTreeNode: null,
      defaultProps: {children: 'zones', label: 'name', isLeaf: 'leaf'},
      //user-->列表
      PMSTATUS: {
        0: {name: "正常", icon: 'el-icon-circle-close', color: '#DC143C'},
        1: {name: "废弃", icon: 'el-icon-circle-check', color: '#30d351'},
      },
      DTSTATUS: syscomm.DEPTTYPES,
      verifyTypes: [],

      showLoading: false,
      filter: {key: undefined},
      page: {list: [], pageNum: 1, pageSize: 10, total: 0},
      //user-->gig
      pmdigShow: false,
      shareUserList: [],
      userdigShow: false,
      projectdigShow: false,

      MAP: undefined,
      pmInfo: {digtitle: "添加项目信息", type: 0},

      deptrules: {
        name: [{required: true, message: '请输入项目名称', trigger: 'blur'}, {
          min: 2,
          max: 25,
          message: '长度在 2 到 25 个字符',
          trigger: 'blur'
        }],
        ss: [{required: true, message: '请输入验证温度范围！', trigger: 'blur'}],
        se: [{required: true, message: '请输入验证温度范围！', trigger: 'blur'}],
        // sr: [{required: true, message: '请输入验证湿度范围！', trigger: 'blur'}],
        // er: [{required: true, message: '请输入验证湿度范围！', trigger: 'blur'}],
        type: [{required: true, message: '请选择验证类型！', trigger: 'blur'}],
        address: [{required: false, message: '请输项目地址！', trigger: 'blur'}]
      },
      //上次文件
      updatefileShow: false,
      formdata: {
        dowtime: '',
        code: '自动',
      },
      fileDataRules: {
        type: [{required: true, message: '请选择文件数据类型！', trigger: 'blur'}],
        datafile: [{required: true, message: '请选择数据文件！', trigger: 'blur'}]
      },
      //下载文件
      locck: false,
      downdfileshow: false,
      //实时数据&&曲线
      mitdigshow: false,
      //
      apconfshow: false,
      //导入数据
      ilock: false,
      //报告
      reportshow: false,
      //
      envdigshow:false

    }
  },
  mounted() {
    this.init();
  },
  onBeforeUnmount() {
    this.closedig();
  },
  methods: {
    init() {
      this.auth = this.$getAuth();
      this.init_base();
      this.initPageData();
      this.$refs.tree.setCheckedKeys([this.$store.state.cuttuser.deptId]);
    },
    init_base() {
      COMM.getObjType({type: 'vt'}).then((res) => {
        this.verifyTypes = res;
      });
    },
    //========================================================================集团&&部门信息========================================================================
    //动态加载树节点
    async loadTreeNode(node, resolve) {
      this.resolve = resolve;
      if (node.level === 0) {
        let data = await DEPT.getRootNode()
        return resolve(data);
      }
      if (node.data && (node.data.isSelect || node.data.leaf)) {
        return resolve([]);
      }
      DEPT.getUserTree({id: node.data.id, isLoadUser: true}).then((data) => {
        data.forEach(d => {
          if (d.leaf) {
            d.identKey = "u" + d.id;
          } else {
            d.identKey = "d" + d.id;
          }
        })
        return resolve(data);
      });
    },
    //slectTree动态加载树节点
    selectNode(node, instanceId) {
      this.pmInfo.uid = node.id.split('_')[1]
      this.pmInfo.did = node.pid
    },

    loadOptions({action, parentNode, callback}) {
      let children = {
        id: '',
        label: '',
        children: null
      }
      let edata = []
      if (action == LOAD_ROOT_OPTIONS) {
        DEPT.getRootNode().then(res => {
          res.forEach(data => {
            children.id = data.type + '_' + data.id,
                children.label = data.name
            edata.push(children)
          })
          this.options = edata
          callback()
        })

      } else {
        let nodeId = parentNode.id.split('_')[1]
        DEPT.getUserTree({id: nodeId, isLoadUser: true}).then((data) => {
          data.forEach((d, index) => {
            if (d.type == 5) {
              delete d.children
            } else {
              d.children = null
            }
            d.label = d.name
            d.id = d.type + '_' + d.id
          })
          parentNode.children = data
          callback()
        });
      }

    },
    setCuttTreeNode(data, node, e) {
      if (this.cuttTreeNode && data.id == this.cuttTreeNode.data.id) {
        return;
      }
      this.cuttTreeNode = node;
      this.initPageData();
    },
    openShareUserTable() {
      this.$nextTick(_ => {
        PM.getSharePorject({mid: this.choseRow.id}).then((res) => {
          if (res.code == 200) {
            this.shareUserList = res.data;
          }
        });
      });
    },
    editorAuth(row) {
      this.choseRow = row;
    },
    showShare(row) {
      this.choseRow = row;
    },


    openDialogTree() {
      this.$nextTick(_ => {
        PM.getSharePorject({mid: this.choseRow.id}).then((res) => {
          let sharIds = [];
          for (var i = 0; i < res.data.length; i++) {
            sharIds.push("u" + res.data[i].id);
          }
          this.choseOids = sharIds;
          this.$refs.userTree.setCheckedKeys(sharIds, true);
        });
      });
    },
    saveAuth() {
      let choseUserId = [];
      let oids = [];
      let choseIds = this.$refs.userTree.getCheckedKeys(true);
      if (choseIds == null || choseIds.length == 0) {
        this.$message.error("请选择需要分享的人员名称！");
        return;
      } else {
        choseIds.forEach(cid => {
          if (cid.indexOf("u") != -1) {
            choseUserId.push(parseInt(cid.replace("u", "")));
          }
        })
        this.choseOids.forEach(coid => {
          oids.push(parseInt(coid.replace("u", "")));
        })
      }
      PM.sharProject({mid: this.choseRow.id, oids: oids, uids: choseUserId}).then((res) => {
        if (res.code = 200) {
          this.$message.success("分享成功");
          this.initPageData();
        } else {
          this.$message.error("分享失败！");
        }
      })
      this.$refs.userTree.setCheckedKeys([]);
      this.choseRow = {};
      this.userdigShow = false;
    },
    closeDialogTree() {

    },

    siscoordinate() {
      let coordinate = this.pmInfo.coordinate;
      if (!coordinate || "" == coordinate) {
        return;
      }
      coordinate = coordinate.replace("[", "").replace("]", "");
      let lglt = coordinate.split(",");
      //维度信息
      if (lglt.length < 2) {
        this.$message.error("经纬度错误！");
        return;
      }
      let point = new BMap.Point(lglt[0], lglt[1]);//  lat
      let mk = new BMap.Marker(point);
      mk.setAnimation(BMAP_ANIMATION_BOUNCE); //跳动的动画
      this.MAP.addOverlay(mk);
      this.MAP.panTo(point);
      // 根据坐标得到地址描述
      let myGeo = new BMap.Geocoder();
      let _this = this;
      myGeo.getLocation(point, function (result) {
        if (result) {
          _this.pmInfo.address = result.address;
        } else {
          _this.$message.error("经纬度错误！");
        }
      });
    },
    closeDialogUserTree() {
      this.$refs.userTree.setCheckedKeys([]);
    },
    loadUserNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{name: '系统', type: 0, id: 0, isSelect: false, leaf: false}]);
      }
      if (node.data && (node.data.isSelect || node.data.leaf)) {
        return resolve([]);
      }
      DEPT.getUserTree({id: node.data.id, isLoadUser: true}).then((data) => {
        return resolve(data);
      });
    },
    setCuttUserNode(data, node, e) {

    },
    refdept() {
      if (this.cuttTreeNode) {
        this.cuttTreeNode.childNodes = [];
        DEPT.getUserTree({id: this.cuttTreeNode.data.id, isLoadUser: false}).then((data) => {
          this.cuttTreeNode.doCreateChildren(data)
        });
      }
    },
    //========================================================================项目信息========================================================================

    initPageData() {
      this.showLoading = true;
      let did = this.$store.state.cuttuser.deptId, nodeType = 0;
      if (null != this.cuttTreeNode) {
        did = this.cuttTreeNode.data.id;
        nodeType = this.cuttTreeNode.data.type;//节点的类型
      }
      PM.list({
        did: did,
        key:this.filter.key,//'高架冷库',//
        nodeType: nodeType,//节点的类型
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.showLoading = false;
        if (200 === res.code) {
          let data = res.pageData;
          this.page.total = data.total;
          this.page.list = data.list;
        } else {
          this.$message.error(res.msg);
          this.page.list = [];
        }
      });
    },
    devlogChange(pageNum) {
      this.page.pageNum = pageNum;
      this.initPageData();
    },
    addOBJ() {
      if (this.cuttTreeNode == null || this.cuttTreeNode.data.type != 5) {
        this.$message.error("请选择一个负责人添加项目！");
        return;
      }

      this.pmInfo = {
        digtitle: '添加项目信息',
        I: 0,
        did: this.cuttTreeNode.data.pid,
        uid: this.cuttTreeNode.data.id,
        uidName: this.cuttTreeNode.data.name,
        activ: "1",
        type: 0,
        ss: 2,
        se: 8,
      };
      this.pmdigShow = true;
    },


    saveobj(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.pmInfo.vifrange = this.pmInfo.ss + "_" + this.pmInfo.se;
          if (undefined != this.pmInfo.sr && undefined != this.pmInfo.er) this.pmInfo.rhvifrange = this.pmInfo.sr + "_" + this.pmInfo.er;
          if (undefined != this.pmInfo.fs && undefined != this.pmInfo.fe) this.pmInfo.fanset = this.pmInfo.fs + "_" + this.pmInfo.fe;
          // if(null==this.pmInfo.endveriftime)this.pmInfo.endveriftime='';
          // if(null==this.pmInfo.startveriftime)this.pmInfo.startveriftime='';
          PM.info(this.pmInfo).then((res) => {
            if (200 == res.code) {
              this.$message.success(res.msg);
              this.pmdigShow = false;
              this.respwddigShow = false;
              this.initPageData();
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
    handle_edit(index, row) {
      if (row.vifrange) {
        let _st = row.vifrange.split(/[_~]+/);
        if (_st.lenght == 1) _st = data.vifrange.split("-")
        row.ss = _st[0];
        row.se = _st[1];
      }
      if (row.rhvifrange) {
        let _st = row.rhvifrange.split(/[_~]+/);
        if (_st.lenght == 1) _st = data.rhvifrange.split("-")
        row.sr = _st[0];
        row.er = _st[1];
      }
      if (row.fanset) {
        let _st = row.fanset.split(/[_~]+/);
        if (_st.lenght == 1) _st = data.fanset.split("-")
        row.fs = _st[0];
        row.fe = _st[1];
      }
      let {...clonerow} = row;
      this.pmInfo = clonerow;
      this.pmInfo.I = 1;
      this.pmdigShow = true;
    },
    handle_del(index, row) {
      this.$confirm('此操作将删除[' + row.name + '] 项目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        PM.deleteProject({id: row.id}).then((res) => {
          if (200 == res.code) {
            this.$message.success("操作成功！");
            this.initPageData();
          } else {
            this.$message.error(res.msg);
          }
        });
      }).catch(() => {
      });
    },

    //--------------------------------------------------------------上传数据--------------------------------------------------------
    showfiledata(row) {
      this.formdata = {
        type: 0,
        mid: row.id,
        code: 'GBK',
        repcards: true,
        repettime: true,
        repveriftime: true,
      };
      this.updatefileShow = true
    },
    setcsv(file) {
      this.formdata.datafile = file.raw;
      return false;
    },
    updatedatafile() {

      this.$refs['fileDataForm'].validate((valid) => {
        if (valid) {
          if (this.ilock) return;
          this.ilock = true;
          let subfrom = new FormData();
          subfrom.append('mid', this.formdata.mid);
          subfrom.append('type', this.formdata.type);
          subfrom.append('file', this.formdata.datafile);
          DATA.impdata(subfrom).then((res) => {
            this.ilock = false;
            if (200 === res.code) {
              this.$message.success("操作成功！");
              this.updatefileShow = false
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },

    //--------------------------------------------------------------下载数据--------------------------------------------------------
    showdowfiledata(row) {
      this.locck = false;
      this.formdata = {type: 0, mid: row.id};
      this.downdfileshow = true
    },
    dowfile(type, filetype) {
      this.locck = true;
      DATA.export({mid: this.formdata.mid, type: type, csv: filetype, dowtime: this.formdata.dowtime}).then(res => {
        this.locck = false;
        if (res.code == 200) {
          this.formdata.url = getUrl() + "/yanzheng-web" + res.data;
        }
      }).catch(res => {
        this.locck = false;
      })
    },
    //--------------------------------------------------------------实时数据----------------------------------------------------------------
    showmitdata(row) {
      this.locck = false;
      this.formdata = {type: 0, mid: row.id};
      this.mitdigshow = true;
    },
    apconf(row) {
      this.locck = false;
      this.formdata = {type: 0, mid: row.id};
      this.apconfshow = true;
    },
    showreport(row) {
      this.locck = false;
      this.formdata = {mid: row.id, name: row.name};
      this.reportshow = true;
    },
    close_apconfig(show) {
      this.apconfshow = false;
    },
    showret(row) {
      this.locck = false;
      this.formdata = {mid: row.id, name: row.name};
      this.envdigshow = true;
    },


  },
}
</script>


